import React from "react";
import Image from "theme/components/atoms/Image";

const NotFound = () => (
  <div className="not-found">
    <div className="not-found__image-container">
      <Image src="/assets/404.jpg" alt="404" format="notfound" />
    </div>
  </div>
);

export default NotFound;
