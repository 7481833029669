import React from "react";
import { ApolloProvider } from "react-apollo";
import { CookiesProvider } from "react-cookie";
import IntlProvider from "web/core/intl/IntlProvider";
import Routes from "./Routes";
import { registerDispatchRoute } from "theme/modules/Router/dispatchRoutes";
import { onInit } from "./front-commerce-app.js";
import { HelmetProvider } from "react-helmet-async";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/merge";
import "rxjs/add/observable/from";
import "rxjs/add/operator/map";
import "rxjs/add/operator/combineLatest";
import "rxjs/add/operator/withLatestFrom";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/do";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/startWith";
import { setObservableConfig } from "recompose";
import { ResponsiveContextProvider } from "theme/components/helpers/MediaQuery";
import { ShopProvider } from "web/core/shop/ShopContext";
import { WishlistProvider } from "theme/modules/Wishlist/WishlistProvider/WishlistProvider";
import { DisplayPriceProvider } from "theme/components/molecules/PriceDisplaySwitch/useDisplayPrice";
import { WebSocketProvider } from "theme/modules/WebSocket/WebSocketProvider.js";
import { PermissionProvider } from "./theme/modules/Permission/PermissionProvider.js";

const rxjsConfig = {
  fromESObservable: Observable.from,
  toESObservable: (stream) => stream,
};
setObservableConfig(rxjsConfig);

export default (client, helmetContext, device, Router) => {
  if (typeof window !== "undefined") {
    /*
     * The window.apolloClient is used for preloading routes
     * Please refer to `web/core/apollo/graphqlWithPreload` for more details.
     * This is how we share the same apolloClient context even though we are
     * not in a React Context when preloading data.
     */
    window.apolloClient = client;

    /**
     * tricks for lazy loading : scroll to bottom page
     * before print to loading images and avoid to display
     * placeholder on the print page
     **/

    window.addEventListener("beforeprint", () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    });
  }

  onInit({
    actions: {
      registerDispatchRoute,
    },
  });

  const App = ({ shop }) => (
    <HelmetProvider context={helmetContext}>
      <WebSocketProvider>
        <ApolloProvider client={client}>
          <PermissionProvider>
            <ShopProvider shop={shop}>
              <IntlProvider>
                <CookiesProvider>
                  <ResponsiveContextProvider value={{ device }}>
                    <WishlistProvider>
                      <DisplayPriceProvider>
                        <Router>
                          <Routes />
                        </Router>
                      </DisplayPriceProvider>
                    </WishlistProvider>
                  </ResponsiveContextProvider>
                </CookiesProvider>
              </IntlProvider>
            </ShopProvider>
          </PermissionProvider>
        </ApolloProvider>
      </WebSocketProvider>
    </HelmetProvider>
  );

  return App;
};
