module.exports = {
  default: {
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "FR",
    url: process.env.FRONT_COMMERCE_URL,
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
};
