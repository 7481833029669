import React from "react";
import { io } from "socket.io-client";

const RECEIVED_MESSAGE_TYPE = "cartsUpdate";

export const WebSocketContext = React.createContext({
  webSocket: null,
  usedRequisitionLists: [],
  connectWebSocket: (userId) => {},
  disconnectWebSocket: () => {},
  sendMessage: (message) => {},
  getWebSocket: () => {},
});

export const WebSocketProvider = ({ children }) => {
  const [webSocket, setWebSocket] = React.useState(null);
  const [usedRequisitionLists, setUsedRequisitionLists] = React.useState({});

  React.useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.disconnect();
      }
    };
  }, [webSocket]);

  const connectWebSocket = (userId) => {
    return new Promise((resolve, reject) => {
      if (typeof window === "undefined") {
        reject(new Error("Socket.io is not supported in this environment."));
        return;
      }

      const socketUrl = "/";

      if (!webSocket) {
        const socketInstance = io(socketUrl, {
          query: { userId },
          secure: true,
          transports: ["websocket"],
        });

        socketInstance.on("connect", () => {
          setWebSocket(socketInstance);
          resolve(socketInstance);
        });

        socketInstance.on("connect_error", (error) => {
          console.error(`Connection error: ${error.message}`, { error });
          reject(error);
        });

        socketInstance.on("disconnect", () => {
          setWebSocket(null);
        });

        socketInstance.on("broadcast", (message) => {
          const parsedMessage = JSON.parse(message);
          if (parsedMessage.type === RECEIVED_MESSAGE_TYPE) {
            setUsedRequisitionLists(parsedMessage.value);
          }
        });
      } else {
        resolve(webSocket);
      }
    });
  };

  const disconnectWebSocket = () => {
    if (webSocket) {
      webSocket.disconnect();
    }
  };

  const sendMessage = (type, data) => {
    if (webSocket && webSocket.connected) {
      webSocket.emit("message", { type, ...data });
    }
  };

  const getWebSocket = () => {
    return webSocket;
  };

  const value = {
    webSocket,
    usedRequisitionLists,
    connectWebSocket,
    disconnectWebSocket,
    sendMessage,
    getWebSocket,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};
