import React from "react";
import classnames from "classnames";

export const PLACEHOLDER = "/images/resized/everblue_placeholder.png";
export const ERROR = "/images/resized/everblue_placeholder.png";

export const transparentExtensions = ["webp", "png"];
export const extensions = ["webp", "jpeg"];
export const defaultExtension = "jpeg";

const Picture = ({ src, sources, alt, sizes, ...props }) => (
  <picture>
    {sources &&
      sources.map((sourceProps) => (
        <source key={sourceProps.type} {...sourceProps} sizes={sizes} />
      ))}
    <img src={src} alt={alt} {...props} />
  </picture>
);

const ImageComponent = React.forwardRef(
  (
    {
      images,
      alt,
      sizes,
      appearance,
      cover,
      imgProps,
      ratio,
      status,
      dangerouslyDisableLazyLoad,
      className,
    },
    ref
  ) => {
    const classes = classnames("responsive-image", {
      [`responsive-image--${appearance}`]: appearance,
      [`responsive-image--done`]: status === "done",
      [`responsive-image--error`]: status === "error",
      [`responsive-image--loading`]: status === "loading" || status === "lazy",
      "responsive-image--cover": cover,
    });

    return (
      <div
        ref={ref}
        className={classes}
        style={
          ratio
            ? { "--image-ratio": `${(ratio * 100).toFixed(0)}%` }
            : undefined
        }
      >
        <Picture
          {...imgProps}
          /* by using the "done" image here, this is how we're triggering the "load" event on the image */
          {...images[status === "loading" ? "done" : status]}
          className={`responsive-image__actual ${className}`}
          alt={alt}
          sizes={sizes}
        />
        {!dangerouslyDisableLazyLoad && (
          <Picture
            {...images.loading}
            className="responsive-image__placeholder"
            alt={status === "loading" ? "Loading" : ""}
            sizes={sizes}
          />
        )}
      </div>
    );
  }
);

export default ImageComponent;
