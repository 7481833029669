import { Component } from "react";

class ScrollTopOnUpdate extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.location !== nextProps.location;
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.hash) {
      const element = document.querySelector(this.props.location.hash);
      if (element) {
        element.scrollIntoView();
        element.focus();
        return;
      }

      if (
        this.props.location.pathname === prevProps.location.pathname &&
        this.props.location.search === prevProps.location.search
      ) {
        return;
      }
    }

    /* Sometimes when the page is cached it will not scroll on update, we just add a timeout 0 to prevent this "bug" */
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
  render() {
    return null;
  }
}

export default ScrollTopOnUpdate;
