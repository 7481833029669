import React from "react";

const DisplayPriceContext = React.createContext({});

const DisplayPriceProvider = ({ children }) => {
  const [isPriceHidden, setPriceHidden] = React.useState(null);

  const showPrice = () => {
    window.localStorage.setItem("isPriceHidden", false);
    setPriceHidden(false);
  };
  const hidePrice = () => {
    window.localStorage.setItem("isPriceHidden", true);
    setPriceHidden(true);
  };

  React.useEffect(() => {
    if (
      window.localStorage &&
      window.localStorage.getItem("isPriceHidden") === "true"
    ) {
      setPriceHidden(true);
    } else {
      setPriceHidden(false);
    }
  }, [setPriceHidden]);

  return (
    <DisplayPriceContext.Provider
      value={{
        isPriceHidden,
        hidePrice,
        showPrice,
      }}
    >
      {children}
    </DisplayPriceContext.Provider>
  );
};

export { DisplayPriceProvider, DisplayPriceContext };

export const useDisplayPrice = () => React.useContext(DisplayPriceContext);
