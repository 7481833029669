import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import PermissionsQuery from "./PermissionQuery.gql";

const PermissionContext = React.createContext({});

const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const { data, loading, error } = useQuery(PermissionsQuery);

  useEffect(() => {
    if (!loading && data && data.me) {
      setPermissions(data.me.permissionsList);
    }
  }, [data, loading, error, permissions]);

  const setPermissionsList = (permissionsList) => {
    setPermissions(permissionsList);
  };

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        setPermissionsList,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export { PermissionProvider, PermissionContext };
