import React from "react";
import Link from "theme/components/atoms/Typography/Link";
import logo from "src/public/assets/logo.svg";

const Logo = ({ classes }) => {
  return (
    <Link to="/">
      <img src={logo} alt="Logo" className={`logo ${classes}`} />
    </Link>
  );
};

export default Logo;
