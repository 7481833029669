import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { presets } from "config/images";
import ImageComponent from "./ImageComponent";
import { useShop } from "web/core/shop/ShopContext";
import makeImagesProps from "./makeImagesProps";
import useImageStatus from "./useImageStatus";
import { Helmet } from "react-helmet-async";

const Image = ({
  src,
  alt,
  format,
  bg,
  cover,
  appearance,
  dangerouslyDisableLazyLoad,
  priority,
  placeholderSrc,
  errorSrc,
  className,
  sizes,
  ...imgProps
}) => {
  dangerouslyDisableLazyLoad = dangerouslyDisableLazyLoad || priority;

  const shop = useShop();
  const images = useMemo(() => {
    return makeImagesProps(
      (shop && shop.imageBaseUrl) || "",
      src,
      format,
      bg,
      cover,
      appearance.indexOf("full") > -1,
      placeholderSrc,
      errorSrc
    );
  }, [shop, src, format, bg, cover, appearance, placeholderSrc, errorSrc]);

  const { imageRef, status, size } = useImageStatus(
    images,
    dangerouslyDisableLazyLoad,
    presets[format]
  );

  const ratio =
    size && size.width && size.height ? size.height / size.width : undefined;

  // We can't select easily the source depending on the browser
  // This means that we will preload the one we'd like to use in priority
  // and browser that don't support this format won't have preload.
  // We might need to improve this process in the future especially since
  // Safari is not supporting webp images.
  const sourceToPreload = images.done.sources[0];

  return (
    <>
      {priority ? (
        <Helmet>
          {sourceToPreload && (
            <link
              rel="preload"
              as="image"
              href={images.done.src}
              imagesrcset={sourceToPreload.srcSet}
              imagesizes={sizes}
              type={sourceToPreload.type}
            />
          )}
        </Helmet>
      ) : null}
      <ImageComponent
        ref={imageRef}
        imgProps={imgProps}
        cover={cover}
        appearance={appearance}
        images={images}
        ratio={ratio}
        sizes={sizes}
        alt={alt}
        status={status}
        dangerouslyDisableLazyLoad={dangerouslyDisableLazyLoad}
        priority={priority}
        className={className}
      />
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  cover: PropTypes.bool,
  format: PropTypes.oneOf(Object.keys(presets)),
  appearance: PropTypes.oneOf(["default", "full", "rounded", "full-rounded"]),
  bg: PropTypes.string,
  dangerouslyDisableLazyLoad: PropTypes.bool,
  priority: PropTypes.bool,
};

Image.defaultProps = {
  appearance: "default",
};

export default Image;
