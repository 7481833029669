module.exports = {
  defaultBgColor: "FFFFFF",
  presets: {
    swatch: { width: 25, height: 25, bgColors: [] },
    thumbnail: { width: 96, height: 96, bgColors: [] },
    small: { width: 136, height: 167, bgColors: [] },
    galleryPreview: { width: 136, height: 136, bgColors: [] },
    categoryPreview: { width: 112, height: 164, bgColors: [] },
    medium: { width: 474, height: 353, bgColors: [] },
    productView: { width: 474, height: 474, bgColors: [] },
    tag: { width: 750, height: 750, bgColors: [] },
    large: { width: 1100, height: 1100, bgColors: [] },
    newsletter: { width: 1280, height: 242, bgColors: [] },
    carousel: { width: 1280, height: 600, bgColors: [] },
    pushBlock: { width: 284, height: 353, bgColors: [] },
    pushBlockWide: { width: 568, height: 353, bgColors: [] },
    cartPreview: { width: 50, height: 50, bgColors: [] },
    wishlistPreview: { width: 50, height: 50, bgColors: [] },
    horizontalCard: { width: 88, height: 73, bgColors: [] },
    zoomable: { width: 1100, height: 1100, bgColors: [], sizes: [2] },
    searchResult: { width: 92, height: 92, bgColors: [] },
    smallProductImage: { width: 102, height: 84, bgColors: [] },
    productItemImage: { width: 474, height: 353, bgColors: ["002e5b"] },
    notfound: { width: 1706, height: 1706, bgColors: [] },
    maintenance: { width: 1706, height: 1706, bgColors: [] },
  },
};
