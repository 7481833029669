import React from "react";
import PropTypes from "prop-types";
import invariant from "fbjs/lib/invariant";
import classNames from "classnames";
import {
  IoIosHome,
  IoIosPerson,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropright,
  IoIosArrowDropleft,
  IoIosArrowDropup,
  IoIosArrowDropdown,
  IoIosAdd,
  IoIosRemove,
  IoIosAddCircle,
  IoIosRemoveCircle,
  IoIosCloseCircle,
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosExit,
  IoIosPhonePortrait,
  IoIosCheckmarkCircle,
  IoIosCheckmark,
  IoIosBusiness,
  IoIosLock,
  IoIosCalendar,
  IoIosCard,
  IoIosArrowRoundDown,
  IoIosShirt,
  IoIosHappy,
  IoIosCog,
  IoIosEye,
  IoMdCreate,
  IoIosTrash,
  IoIosMail,
  IoIosCamera,
  IoIosSync,
  IoIosMenu,
  IoIosList,
  IoIosRedo,
  IoIosCart,
  IoIosHeart,
  IoIosSearch,
  IoIosCash,
  IoIosCube,
  IoIosStar,
  IoIosStarHalf,
  IoIosOptions,
  IoIosDownload,
  IoIosUndo,
  IoMdShare,
  IoIosWarning,
  IoIosPeople,
  IoIosEyeOff,
} from "react-icons/io";

import { IoDocumentTextOutline } from "react-icons/io5";

import {
  BsDownload,
  BsListTask,
  BsHeadset,
  BsBoxSeam,
  BsPencilSquare,
  BsArrowUpShort,
  BsArrowDownShort,
} from "react-icons/bs";
import { GrTag } from "react-icons/gr";
import {
  RiAccountBoxLine,
  RiFacebookBoxFill,
  RiLinkedinBoxFill,
  RiInstagramFill,
  RiYoutubeFill,
} from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import {
  FaUserCircle,
  FaGoogle,
  FaRegAddressBook,
  FaPinterestP,
  FaHouzz,
  FaWrench,
} from "react-icons/fa";
import { GiOrganigram, GiFactory } from "react-icons/gi";
import { VscGripper } from "react-icons/vsc";
import { GrClose, GrDrag } from "react-icons/gr";
import { AiFillWarning, AiFillPrinter } from "react-icons/ai";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { CgSortAz, CgSortZa, CgFileDocument } from "react-icons/cg";
import { GoTriangleDown, GoDesktopDownload } from "react-icons/go";
import { HiInformationCircle } from "react-icons/hi";
import { RiHeartLine, RiHeartFill } from "react-icons/ri";

const keyToComponent = {
  home: IoIosHome,
  user: IoIosPerson,
  "chevron-right": IoIosArrowForward,
  "chevron-left": IoIosArrowBack,
  "chevron-up": IoIosArrowUp,
  "chevron-down": IoIosArrowDown,
  "chevron-right-circle": IoIosArrowDropright,
  "chevron-left-circle": IoIosArrowDropleft,
  "chevron-up-circle": IoIosArrowDropup,
  "chevron-down-circle": IoIosArrowDropdown,
  plus: IoIosAdd,
  minus: IoIosRemove,
  "plus-circle": IoIosAddCircle,
  "circle-minus": IoIosRemoveCircle,
  "cross-circle": IoIosCloseCircle,
  exit: IoIosExit,
  "phone-handset": IoIosPhonePortrait,
  "checkmark-circle": IoIosCheckmarkCircle,
  checkmark: IoIosCheckmark,
  store: IoIosBusiness,
  lock: IoIosLock,
  "calendar-full": IoIosCalendar,
  "credit-card": IoIosCard,
  "sort-amount-asc": IoIosArrowRoundDown,
  shirt: IoIosShirt,
  smile: IoIosHappy,
  cog: IoIosCog,
  eye: IoIosEye,
  "eye-off": IoIosEyeOff,
  pencil: IoMdCreate,
  trash: IoIosTrash,
  envelope: IoIosMail,
  camera: IoIosCamera,
  magnifier: IoIosSearch,
  sync: IoIosSync,
  menu: IoIosMenu,
  list: IoIosList,
  "list-task": BsListTask,
  redo: IoIosRedo,
  cart: IoIosCart,
  heart: IoIosHeart,
  cash: IoIosCash,
  cube: IoIosCube,
  star: IoIosStar,
  "star-half": IoIosStarHalf,
  options: IoIosOptions,
  download: IoIosDownload,
  undo: IoIosUndo,
  share: IoMdShare,
  "download-light": BsDownload,
  warn: IoIosWarning,
  users: IoIosPeople,
  "user-circle": FaUserCircle,
  organigram: GiOrganigram,
  gripper: VscGripper,
  google: FaGoogle,
  cross: GrClose,
  "fill-warning": AiFillWarning,
  headset: BsHeadset,
  tag: GrTag,
  "address-book": FaRegAddressBook,
  account: RiAccountBoxLine,
  logout: MdLogout,
  print: AiFillPrinter,
  checked: ImCheckboxChecked,
  unchecked: ImCheckboxUnchecked,
  sortAsc: CgSortZa,
  sortDesc: CgSortAz,
  "ccli-document": BsPencilSquare,
  "bcli-document": BsBoxSeam,
  "bdiv-document": GiFactory,
  "bcfac-document": GiFactory,
  "fact-document": CgFileDocument,
  "grouped-product-icon": GoTriangleDown,
  facebook: RiFacebookBoxFill,
  linkedin: RiLinkedinBoxFill,
  instagram: RiInstagramFill,
  youtube: RiYoutubeFill,
  pinterest: FaPinterestP,
  houzz: FaHouzz,
  "account-download": GoDesktopDownload,
  drag: GrDrag,
  "arrow-up": BsArrowUpShort,
  "arrow-down": BsArrowDownShort,
  wrench: FaWrench,
  info: HiInformationCircle,
  "not-saleable": IoDocumentTextOutline,
  "wishlist-off": RiHeartLine,
  "wishlist-on": RiHeartFill,
};

const svgIcons = [
  "credit-card",
  "primary-percentage",
  "primary-new-tag",
  "white-new-tag",
  "white-percentage",
  "basket",
  "cart-white",
  "user",
  "menu",
  "pdf",
  "account-tracking",
  "quote",
  "ecatalog-orders",
  "orders",
  "login-info",
  "customer-service",
  "address-book",
];

export const whitelist = [...Object.keys(keyToComponent), ...svgIcons].sort();

const wrapperComponents = {
  default: ({ children }) => {
    return children;
  },
  withAppearance: ({ children, appearance, size, icon }) => {
    const className = classNames("icon-wrapper", {
      [`icon-${icon}`]: icon,
      [`icon-wrapper--${appearance}`]: appearance,
      [`icon-wrapper--${size}`]: size,
    });
    return <div className={className}>{children}</div>;
  },
};

const Icon = ({ icon, size, title, appearance }) => {
  invariant(
    whitelist.indexOf(icon) > -1,
    `"${icon}" is not a whitelisted icon`
  );

  if (process.env.NODE_ENV === "development" && title === undefined) {
    console.warn(
      "You must set a title (it can be an empty string if you don't want one) to use <Icon />."
    );
  }

  const className = classNames("icon", {
    [`icon--${size}`]: size,
    [`icon--${appearance}`]: appearance,
  });

  const Wrapper = appearance
    ? wrapperComponents.withAppearance
    : wrapperComponents.default;

  if (svgIcons.indexOf(icon) > -1) {
    return (
      <Wrapper appearance={appearance} size={size} icon={icon}>
        <img
          title={title}
          src={`/images/icons/${icon}.svg`}
          alt=""
          className={className}
        />
      </Wrapper>
    );
  } else {
    const IconComponent = keyToComponent[icon];
    return (
      <Wrapper appearance={appearance} size={size} icon={icon}>
        <IconComponent title={title} className={className} />
      </Wrapper>
    );
  }
};

Icon.defaultProps = {
  size: "default",
  appearance: "default",
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf([
    "default",
    "default-block",
    "block",
    "round",
    "round-border",
  ]),
  size: PropTypes.oneOf(["default", "small", "mini", "medium", "big"]),
  title: PropTypes.string.isRequired,
};

export default Icon;
