import qs from "web/core/utils/queryString";
import QueryHelper from "./queryHelper";

const makeQueryFromLocation = (location, facetsParams = {}) => {
  const query = new QueryHelper();
  const isSearch = location?.pathname.includes("/search/");
  return query
    .makeNew(qs.parse(location.search), isSearch)
    .withFacets(facetsParams);
};

export default makeQueryFromLocation;
