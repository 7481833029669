import React from "react";
import { Helmet } from "react-helmet-async";
import Image from "theme/components/atoms/Image";

const Maintenance = () => {
  return (
    <div className="wrapper">
      <Helmet></Helmet>

      <div className="maintenance">
        <h1 className="maintenance__image-container">
          <Image
            priority
            src="/assets/503.jpg"
            alt="503"
            format="maintenance"
          />
        </h1>
      </div>
    </div>
  );
};

export default Maintenance;
